import React from "react";
import { Link } from "react-router-dom";
import StygigLogo from "../../assets/Vectors/StygigLogo";
import "./Header.css";
const Header = () => {
  return (
    <Link to="/" style={{ color: "inherit", textDecoration: "inherit" }}>
      <div className="headerSection">
      <div className="logo">
      <StygigLogo />
      </div>
        <div className="title">StyGig</div>
      </div>
    </Link>
  );
};

export default Header;
