import React from "react";
import AndroidIcon from "../../assets/Vectors/AndroidIcon";
import AppleIcon from "../../assets/Vectors/AppleIcon";
import QRVector from '../../assets/Vectors/QR';
import "./GetApp.css";
const GetApp = ({ isOpen, onClose, children }) => {
  return isOpen ? (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="qrcircle">
          <div className="qrBackground">
            <div className="qrContainer">
              <QRVector />
            </div>
            <div className="seperator" />
            <div className="iconContainer">
              <a
                href="https://apps.apple.com/us/app/stygig/id6451193976"
                target="_blank"
                style={{display:'flex'}}
              >
                <AppleIcon />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.stygig"
                target="_blank"
                style={{display:'flex'}}
              >
                <AndroidIcon />
              </a>
            </div>
          </div>
          <div className="scan">Scan to download</div>
        </div>
      </div>
    </div>
  ) : null;
};

export default GetApp;
