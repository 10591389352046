import React from "react";
import "./Chips.css";

const Chips = () => {
  const firstRow = [
    "silk",
    "suit",
    "nightlife",
    "fitness",
    "jacket",
    "sneakers",
    "boots",
  ];
  const secondRow = [
    "Ethnic",
    "highheel",
    "wedding",
    "saree",
    "shorts",
    "formals",
    "gown",
  ];

  return (
    <div className="marquee-wrapper">
      <div className="marquee-container">
        <div className="marquee-block">
          <div className="marquee-inner">
            {firstRow.map((chip, index) => (
              <div className="chip" key={index}>
                {chip}
              </div>
            ))}
            {firstRow.map((chip, index) => (
              <div className="chip" key={index}>
                {chip}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="marquee-container">
        <div className="marquee-block">
          <div className="marquee-inner to-left">
            <div className="chip blue">trousers</div>
            <div className="chip red">jeans</div>
            <div className="chip yellow">club</div>
            <div className="chip blue">robe</div>
            <div className="chip yellow">pajamas</div>
            <div className="chip red">beach</div>
            <div className="chip yellow">Party</div>
            <div className="chip blue">trousers</div>
            <div className="chip red">jeans</div>
            <div className="chip yellow">club</div>
            <div className="chip blue">robe</div>
            <div className="chip yellow">pajamas</div>
            <div className="chip red">beach</div>
            <div className="chip yellow">Party</div>
          </div>
        </div>
      </div>
      <div className="marquee-container">
        <div className="marquee-block">
          <div className="marquee-inner">
            {secondRow.map((chip, index) => (
              <div className="chip" key={index}>
                {chip}
              </div>
            ))}
            {secondRow.map((chip, index) => (
              <div className="chip" key={index}>
                {chip}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chips;
