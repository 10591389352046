import React, { useEffect } from "react";
import SecondaryHeader from "../Header/SecondaryHeader";
import "../TermsAndConditions/TermsAndConditions.css";
const PrivacyPolicy = ({ openModal }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="tcMain">
      <SecondaryHeader openModal={openModal} subtitle={"Privacy Policy"} />

      <div className="privacyBody">
        <div>Updated on November 05, 2023</div>
        <div>
          We at StyGig Private Limited (together with our affiliates, “StyGig”,
          “we”, “our” or “us”) respect your privacy and are strongly committed
          to keeping secure any information we obtain from you or about you.
          This Privacy Policy describes our practices with respect to Personal
          Information we collect from or about you when you use StyGig
          (collectively, “Services”). By accessing, downloading, or using the
          website or the allied mobile application, StyGig App (Application) you
          agree to be governed by this Privacy Policy.
        </div>
        <div className="tcBold">Scope</div>
        <ul>
          <li>
            This Privacy Policy applies to the Services, Application, or any
            online application or service that refers to or links to this
            Privacy Policy. This Privacy Policy applies regardless of whether
            you have downloaded or accessed or used the Application from your
            mobile telephone or handheld device or any other computer resource
            to access our Services.
          </li>
          <li>
            StyGig is a social media platform. The users acknowledge and
            understand that any content (including their user handle, profile
            picture and published photos, videos and posts ) created by the
            users, is publicly available and searchable by anyone around the
            world. It is advised that Users do not post any personal (or
            sensitive) information on the Application. You should be careful of
            what you are posting on the Application, as the updates will reflect
            in your feed and will be visible to other Users of the Application,
            or anyone else who accesses our Services, depending upon the privacy
            setting specifically chosen by you for your account.
          </li>
          <li>
            You acknowledge and agree that by providing public Content on StyGig
            , you are authorising and advising us to disclose that information
            on the Application and allow for wide circulation. When you are
            using hashtags, our APIs, and that of other third- party service
            providers will also have access to such information. We do not
            govern the operations of these entities, and you may be required to
            adhere to their policies, hence, we urge you to refer to their
            policies and familiarise yourself with the same, prior to engaging
            with such third-party platforms.
          </li>
        </ul>
        <div className="tcBold">Information We Collect</div>
        <ol>
          <li>
            At the time of registration: When you choose to register on StyGig
            Application, we will seek certain identifiers which qualify as
            personal data (as defined at law), and some of these identifiers
            will have to be mandatorily collected, and some will have to be
            collected only upon your discretion, and consent.
          </li>
          <li>
            The information which you provide to us, mandatorily are: Mobile
            number, Username, E-mail address, profile photo Etc, For creation of
            a unique profile, identification, authentication via OTP and any
            communications from us or on our behalf or by any third parties
            engaged by us to provide the Service.
          </li>
          <li>
            The information which you may choose to additionally provide, are:
            <ol type="a">
              <li>Name: for purposes of displaying on your profile;</li>
              <li>
                User handle preference: for the purposes of identification on
                the Application;
              </li>
              <li>
                Date of Birth: for the purposes of identification and/or
                displaying on your profile;
              </li>
              <li>Gender: for the purposes of displaying on your profile;</li>
              <li>
                Profile Picture: for the purposes of displaying on your profile;
              </li>
              <li>
                Location: for the purposes of customising your experience on the
                Application;
              </li>
              <li>
                Professional details: for the purposes of displaying on your
                profile and for customisation of the Content, and other services
                offered to you;
              </li>
              <li>
                Description of Self: for the purposes of displaying on your
                profile;
              </li>
              <li>
                Relationship Status: for the purposes of displaying on your
                profile;
              </li>
              <li>
                Banking information: for the purposes of redeeming any rewards
                or conducting any transactions on the StyGig platform, as may be
                allowed under relevant laws.
              </li>
            </ol>
          </li>
          <li>
            At the time of profile verification – In order to verify your
            profile, we may collect the following information from you:
            <ol type="a">
              <li>Mobile number; </li>
              <li>Email ID; </li>
              <li>
                Government-issued identification document(s). We will ask you
                for this information only when you seek to become an
                authenticated profile on the Application, while using our
                Services, and such information is not disclosed to the public.
              </li>
              <li>
                Information to Third Party Service – In addition, if you
                authorise us to do so, we may grant/link third party services
                with StyGig (eg. Facebook, Twitter, Instagram, Snapchat,
                WhatsApp, Pinterest, etc)
              </li>
              <li>
                Information from your use of our Services – When you use our
                Services, we may collect the following information
                automatically:
                <ol type="i">
                  <li>
                    Content of the StyGig (text, images, graphics, audio, video
                    , etc.);
                  </li>
                  <li>Users you follow on the Application; </li>
                  <li>Users who follow you on the Application; </li>
                  <li>People who have visited your profile and vice versa;</li>
                  <li>
                    Information on your browser’s user agent string and server
                    logs, including your IP address, information in the cookies
                    and activity on the Application ;
                  </li>
                  <li>
                    URL information, time stamp, visit information, your
                    browsing history on the Application;
                  </li>
                  <li>
                    Device information, such as make, model and operating system
                    of the device used for browsing the Application;{" "}
                  </li>
                  <li>Date of download and/or reinstall of the App;</li>
                  <li>
                    Events related to your actions (follows, reactions to posts
                    , time spent, how often and when you visit the Application,
                    etc);{" "}
                  </li>
                  <li>
                    Users who send you chat requests and have chatted with you;
                  </li>
                  <li> The Unique Device Identifier on the Application;</li>
                  <li>Language.</li>
                  <li>
                    Locational information as permitted by you in your mobile
                    device settings, We do not collect any further information
                    which is not specifically communicated to you as per this
                    Privacy Policy.{" "}
                  </li>
                  <li>
                    You have the ability and choice of restricting certain kinds
                    of information from being collected by adjusting your phone
                    and device settings. Please refer to your phone and device
                    user manuals for more information.{" "}
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            Surveys – We may collect additional information at other times,
            including but not limited to, when you provide feedback(s), modify
            your content or email preferences, respond to surveys, provide
            comments, or communicate with us. This information may include your
            name, e-mail id, mobile number, location, etc. and only such
            information that you may choose to specifically provide to us.
          </li>
          <li>
            Cookies – We use cookies and similar technologies which are
            necessary to its functioning. Some of these cookies are essential
            for us to provide you with the Services on the App. We, or our
            third-party service providers, may use cookies, mobile app analytics
            and similar technologies to track visitor activity and collect data
            on the App. We may combine this data with other personal data we
            have collected from users.
          </li>
        </ol>
        <div className="tcBold">Why We Collect This Information</div>
        <ol>
          <li>
            {" "}
            We collect information to provide the Services and for the purposes
            as outlined below: To help us identify you when you log onto the
            Application and when you register an account with us, and, to
            validate, authorise and map a specific profile with an authorised
            user; To enhance the quality of the Services that we provide and
            improve your experience during browsing; For providing
            location-based services, as and where requested by you; For the
            performance of legal obligations; To communicate with you; To
            provide you with StyGig posts, special offers, general information
            about other products and services along with marketing information
            and surveys, as agreed to by you; To provide and process service
            requests initiated by you. When We Share Your Information We may
            share any information with our trusted partners or third parties who
            provide us with infrastructure support services, for meeting the
            obligations agreed to between you and us. We may also share
            aggregated, non-personally identifiable information publicly and
            with our partners, like publishers, advertisers or connected sites
            to show trends about the general use of our Services. We will seek
            your consent prior to using or sharing your personal information for
            any other purpose, if so, identified at a later stage.
          </li>
          <li>
            We may also use your information to perform analytics and conduct
            customer research, to determine your interest, for identifying
            content that generates sales and to analyse traffic patterns.
          </li>
          <li>
            {" "}
            We also use your information to market to you as per the laws of
            your country, and to the extent permissible.
          </li>
          <li>
            You have the ability to allow us to share your information to third
            parties so that you can avail their services. You may disable or
            limit such sharing at any time.
          </li>
        </ol>
        <div className="tcBold">Disclosure Of Information</div>
        We may also disclose your Personal Information only:
        <ol>
          <li>
            {" "}
            As required by law, such as to comply with a judicial order,
            executive orders, requirement by a law enforcement authority, or by
            other legal processes.
          </li>
          <li>
            When your desired products and services can only be provided if your
            personal information is disclosed. When we believe, in good faith,
            that disclosure is necessary to protect our rights, protect your
            safety or the safety of others, or, investigate fraud or crime;
          </li>
          <li>
            If we (or our affiliates) are involved in a merger, acquisition, or
            sale of all or substantially all of its assets or equity. Such
            information shall be disaggregated to the extent possible and shall
            be subject to execution of appropriate non disclosure agreements and
            binding privacy undertakings.
          </li>
        </ol>
        <div className="tcBold">User Rights On StyGig</div>
        We want to ensure that you are fully empowered on the StyGig platform
        and are completely aware of the rights that you are entitled to in the
        course of using our platform. You have a number of rights in relation to
        the information we hold about you.
        <ol>
          <li>
            Access – The right to access the information we hold about you, and
            to obtain details of the processing. You also have the right to
            access a list of all the third parties that have your Personal
            Information through us.
          </li>
          <li>
            Rectification – The right to seek, correct, update and modify the
            information available with us, to ensure accuracy.
          </li>
          <li>
            Cancellation – The right to seek to cancel or erase, free of charge,
            your personal data when it is inadequate, excessive, or unnecessary.
            This shall be subject to lawful processing measures and any legal
            prescriptions.
          </li>
          <li>
            Objection – The right to withdraw your consent to our continued
            processing of the information, at any point of time, subject only to
            any legitimate reason for continued processing in certain
            circumstances.
          </li>
          <li>
            Portability – The right to seek from us your personal data to be
            provided to another service provider, in a machine-readable format
            that we use at the time the request was made.
          </li>
        </ol>
        You may exercise any of these rights by mailing us at contact@stygig.com
        This shall be subject to the legal requirements, and our internal
        procedure.
        <div className="tcBold">
          How Long Will Your Personal Information Be Stored With Us?
        </div>
        In relation to other Personal Information, we store them for certain
        predetermined periods on the basis of statutory and legal requirements;
        industry guidelines, de-identified or pseudonymised data sets to be used
        in an aggregated format for scientific, statistical or historical
        purposes.{" "}
        <div>
          We retain the information which we have collected for a period no
          longer than is necessary, and as may be required in law. If we need to
          retain your Personal Information for a longer period, we will inform
          you prior to the extension of the storage period and seek your
          explicit consent to extend the retention period. We will delete your
          information whenever you request us to do so. However, we may archive
          and/or retain some information for legal purposes. Any other
          information which is processed by us for analytical purposes, will
          only be processed in an aggregated or non- identifiable basis.{" "}
        </div>
        Opting Out You can always opt out of our Services or choose not to
        disclose information to us at any time. However, keep in mind that some
        information may be needed to register with us or to take advantage of
        some of our special features. By providing limited information, or by
        availing the opt-out provision, you may not be able to access full
        functionalities of our Services and Application, and some features may
        be disabled for your access.
        <div>
          We reserve the right to continue to keep a copy of any of your
          Personal Information if required by law. We may use any
          aggregated/anonymised data derived from your account, in a matter
          which shall not infringe upon your privacy.
        </div>
        <div className="tcBold">The Security Of Your Information</div>
        Your personal data is maintained by us in electronic form. We shall take
        all necessary precautions to protect your personal data and implement
        reasonable security practices and measures including certain managerial,
        technical, operational and physical security control measures that are
        commensurate with respect to the information being collected and the
        nature of our business. Specifically, we will ensure that the security
        infrastructure put in place by us to safeguard your Personal Information
        against loss, unauthorised access, destruction, use, processing,
        storage, modification or de-anonymisation, will at all times be adherent
        to the best industry standards. We restrict access to personal
        information to Company employees, contractors, and agents who need that
        information in order to process it. Anyone with this access is subject
        to strict contractual confidentiality obligations and may be disciplined
        or terminated if they fail to meet these obligations.
        <div className="tcBold">GDPR Compliance</div>
        The Application may be accessed and used by residents of the European
        Economic Area (EEA), who would be regulated by the Regulation (EU)
        2016/679 of the European Parliament and of the Council of 27 April 2016
        on the protection of natural persons with regard to the processing of
        personal data and on the free movement of such data and repealing
        Directive 95/46/EC (General Data Protection Regulation) (GDPR). EU
        citizens may reach out to us at: contact@stygig.com with a subject line
        “GDPR compliance”. We respond to all requests we receive from
        individuals wishing to exercise their data protection rights in
        accordance with applicable data protection laws. Any transfer of data
        generated from EU citizens shall be subject to the data transfer
        compliances outlined in GDPR.
        <div className="tcBold">Overseas Transfer</div>
        Your information may be transferred to and stored in locations outside
        laws of the territory in which the Company is registered and where the
        Application is registered on the app stores. We will do this only when
        the destination jurisdiction has an adequate and appropriate level of
        protection and where the transfer is lawful, and only when the same is
        required for us to meet our contractual and statutory obligations, and
        only where the laws of your country allow us to do so. For completeness,
        the information which may be transferred outside is such information
        which may be sent to foreign jurisdictions as per the applicable laws.
        When we transfer your personal data from your Home Country (country,
        state and city in which you are present) to the Alternate Country
        (another country, state and city), we will comply with our legal and
        regulatory obligations in relation to your personal data, including
        having a lawful basis for transferring personal data and putting
        appropriate safeguards in place to ensure an adequate level of
        protection for the personal data. We will also ensure that the recipient
        in Alternate Country is obliged to protect your personal data at a
        standard of protection comparable to the protection under applicable
        laws. Our lawful basis for such transfer will be either on the basis of
        content or one of the safeguards permissible by laws. For transfer of
        data outside the EEA, we will follow adequate safeguards mandated under
        the GDPR. We ensure an adequate level of protection for the rights of
        data subjects based on the adequacy of the receiving country’s data
        protection laws, contractual obligations placed on the recipient of the
        data (model contractual clauses).
        <div className="tcBold">Children</div>
        You must have attained the age of 13 to be able to use and access our
        Services. If you are a minor in your jurisdiction, your registration and
        use of our Services must be with the supervision of an adult. As a
        parent or legal guardian, please do not allow your minors under your
        care to submit Personal Information to us. In the event that such
        personal data of a minor is disclosed to us, you hereby consent to the
        processing of the minor’s personal data and accept and agree to be bound
        by this Privacy Policy and take responsibility for his or her actions.
        <div className="tcBold">Personal Data Of Others</div>
        In some situations, you may provide personal data of other individuals
        (family, friends, likewise) to us. If you provide us with such personal
        data, you represent and warrant that you have obtained their consent for
        their Personal Information to be collected, used and disclosed as set
        out in this Privacy Policy.
        <div className="tcBold">Changes to this Privacy Policy</div>
        We make periodical changes to the Privacy Policy. Any significant
        changes we may make to our Privacy Policy in the future will be promptly
        notified to Users by posting the relevant terms in a prominent position
        on the webpage. The new terms may be displayed on the webpage, and you
        will be required to read and accept them to continue your use of the
        Services.
        <div className="tcBold">Contact us</div>
        If you have any questions or concerns about our Privacy Policy, please
        contact us at contact@stygig.com
      </div>
    </div>
  );
};

export default PrivacyPolicy;
