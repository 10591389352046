import React from "react";
import { Link } from "react-router-dom";
import Contact from "../../assets/Images/contact@stygig.com.png";
import StygigLogo from "../../assets/Vectors/StygigLogo";

import InstagramIcon from "../../assets/Vectors/InstagramIcon";
import LinkedInIcon from "../../assets/Vectors/LinkedInIcon";
import TwitterIcon from "../../assets/Vectors/TwitterIcon";
import "./Footer.css";
function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <div className="footerContainer">
      <div className="subContainer">
        <div className="footerTypography">©{currentYear} StyGig</div>
        <div style={{ display: "flex", gap: "0.69vw" }}>
          <a href="https://stygig.com/privacy-policy.html" className="footerTypography2" target="_blank">
            privacy policy
          </a>
          <div className="footerTypography2">&#8226;</div>
          <a href="https://stygig.com/terms-conditions.html" className="footerTypography2" target="_blank">
            terms and conditions
          </a>
        </div>
      </div>
      <div className="contactContainer">
        <div>
          <a
            href="mailto:contact@stygig.com"
            target="_blank"
            className="contact"
          >
            contact@stygig.com
          </a>
        </div>
        <div className="iconsContainer">
          <a href="mailto:contact@stygig.com" target="_blank" className="icons">
            <StygigLogo />
          </a>
          <a
            href="https://instagram.com/stygig_official?igshid=NGVhN2U2NjQ0Yg%3D%3D&utm_source=qr"
            target="_blank"
            className="icons"
          >
            <InstagramIcon />
          </a>
          <a
            href="https://www.linkedin.com/company/stygig/"
            target="_blank"
            className="icons"
          >
            <LinkedInIcon />
          </a>
          <a
            href="https://x.com/stylygig?s=11&t=P1BiSFqhU5Z048Jdwkb3cA"
            target="_blank"
            className="icons"
          >
            <TwitterIcon />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
