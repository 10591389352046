import React, { useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import Main from "./components/Main";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import TermsAndConditions from "./components/TermsAndConditions/TermsAndConditions";
import GetApp from "./components/GetApp/GetApp";

export default function App() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [isModalOpen]);
  return (
    <Router>
      <div className="App">
        <GetApp isOpen={isModalOpen} onClose={closeModal} />

        <Routes>
          <Route path="/termsandconditions" element={<TermsAndConditions openModal={openModal}/>} />
          <Route path="/privacypolicy" element={<PrivacyPolicy openModal={openModal}/>} />
          <Route path="/" element={<Main openModal={openModal} />} />
        </Routes>
      </div>
    </Router>
  );
}
