import React from "react";

const AppleIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="2.64vw"
    height="2.64vw"
    viewBox="0 0 36 36"
    fill="none"
  >
    <path
      d="M25.3486 30.234C23.9293 31.6098 22.3796 31.3926 20.8879 30.7409C19.3093 30.0747 17.861 30.0457 16.1955 30.7409C14.11 31.6388 13.0093 31.3781 11.7638 30.234C4.69619 22.9491 5.73895 11.8554 13.7624 11.4498C15.7176 11.5512 17.079 12.5216 18.2231 12.6085C19.9321 12.2609 21.5686 11.2616 23.3934 11.3919C25.5803 11.5657 27.2314 12.4347 28.3176 13.9988C23.799 16.7071 24.8707 22.6595 29.0127 24.325C28.1872 26.4974 27.1155 28.6554 25.3341 30.2485L25.3486 30.234ZM18.0783 11.3629C17.861 8.13328 20.4824 5.46845 23.4948 5.20776C23.9148 8.94432 20.1058 11.725 18.0783 11.3629Z"
      fill="white"
    />
  </svg>
);

export default AppleIcon;
