import React from 'react'
import { Link } from 'react-router-dom'
import StygigLogo from '../../assets/Vectors/StygigLogo'

const SecondaryHeader = ({openModal, subtitle}) => {
  return (
    <div className="tcHeader">
    <div className="tcHeader2">
      <Link to="/" style={{ color: "inherit", textDecoration: "inherit" }}>
        <div className="tcHeaderSection">
          <div className="tcLogo">
            <StygigLogo />
          </div>
          <div className="tcTitle">StyGig</div>
        </div>
      </Link>
      <div className="tcSeperator" />
      <div className="subtitle">{subtitle}</div>
    </div>
    <button className="tcGetButton" onClick={openModal}>Get App</button>
  </div>
  )
}

export default SecondaryHeader