import React, { useState } from "react";
import CasualHomeScreen from "../../assets/Images/Casual-HomeScreen.png";
import EthnicHomeScreen from "../../assets/Images/Ethnic-HomeScreen.png";
import PartyHomeScreen from "../../assets/Images/Party-HomeScreen.png";
import SpecialHomeScreen from "../../assets/Images/Special-HomeScreen.png";
import "./HoverCardGallery.css";
const HoverCardGallery = () => {
  const [hoverImage, setHoverImage] = useState(null);
  return (
    <div className="screenContainer">
      <div>
        <img
          src={hoverImage ? hoverImage : CasualHomeScreen}
          alt="HomeScreen"
          className="homeScreen"
        />
      </div>
      <div className="textContainer">
        <div
          className={hoverImage ? `casualText` : `casualTextLarge`}
          onMouseEnter={() => setHoverImage(CasualHomeScreen)}
          onMouseLeave={() => setHoverImage(null)}
        >
          casual
        </div>
        <div
          className="partyText"
          onMouseEnter={() => setHoverImage(PartyHomeScreen)}
          onMouseLeave={() => setHoverImage(null)}
        >
          party
        </div>
        <div
          className="ethnicText"
          onMouseEnter={() => setHoverImage(EthnicHomeScreen)}
          onMouseLeave={() => setHoverImage(null)}
        >
          ethnic
        </div>
        <div
          className="specialText"
          onMouseEnter={() => setHoverImage(SpecialHomeScreen)}
          onMouseLeave={() => setHoverImage(null)}
        >
          special
        </div>
      </div>
    </div>
  );
};

export default HoverCardGallery;
