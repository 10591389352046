import React from "react";

const StygigLogo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    // width="120"
    // height="120"
    viewBox="0 0 120 120"
    fill="none"
  >
    <g clip-path="url(#clip0_1_221)">
      <path
        d="M102 120H18C8.05781 120 0 111.942 0 102V18C0 8.05781 8.05781 0 18 0H102C111.942 0 120 8.05781 120 18V102C120 111.942 111.942 120 102 120Z"
        fill="url(#paint0_linear_1_221)"
      />
      <path
        d="M70.209 48.533C72.8387 46.2783 80.2544 39.1533 75.445 36.3408C72.3512 34.5268 66.7403 34.5689 63.1028 34.6299C58.4903 34.7846 49.7669 35.1924 46.5325 38.3986C44.62 40.3721 47.0106 43.4143 48.4965 45.0924C50.0247 46.7752 51.834 48.2893 53.6903 49.6861C59.5356 54.0174 66.0559 57.4627 72.7215 60.6361C80.034 63.9314 87.234 68.1502 92.0059 74.5252C96.6981 80.7314 97.6309 88.5127 92.7559 94.9205C88.5419 100.447 81.7872 103.527 75.07 105.392C64.8747 108.106 54.1684 108.425 43.6684 107.586C35.9387 106.855 23.3387 104.741 24.0231 95.1314C24.6559 89.1033 30.1825 83.2486 34.3497 78.9924C37.7481 75.608 41.3622 72.4299 45.0606 69.3408C45.3653 69.0877 45.7825 69.4814 45.52 69.7721C38.6715 77.1877 26.4934 90.1018 28.12 96.6736C29.7044 103.002 45.8997 102.716 51.4919 102.641C62.4934 102.35 76.945 100.803 85.3637 93.608C87.5856 91.6533 89.245 89.0939 89.4465 86.2158C89.8637 77.8674 80.259 71.1689 73.2278 67.5033C67.495 64.6768 61.8137 61.7564 56.4934 58.2455C52.1294 55.3486 47.8403 52.1752 44.4934 48.1674C42.0794 45.2518 39.9137 41.2439 41.6669 37.5221C44.8262 31.0955 56.7747 31.2221 63.1497 31.4799C67.5278 31.7518 75.8153 32.3471 78.1778 36.4018C80.6715 40.9674 73.7528 46.2877 70.209 48.533Z"
        fill="white"
      />
      <path
        d="M57.5298 12.9661C56.4095 15.5395 53.0157 22.252 54.0892 24.6801C54.1876 24.8489 54.272 24.9192 54.436 24.9989C54.5767 25.0645 54.8204 25.1348 55.1485 25.1723C55.9079 25.252 56.8032 25.1536 57.6095 25.0036C58.9876 24.7551 60.422 24.352 61.711 23.8692C64.4298 22.852 68.0626 20.6442 67.2423 17.4707C66.6001 15.2723 64.0173 14.002 61.7626 13.4442C61.0548 13.2661 60.347 13.1442 59.6251 13.0457C59.2126 12.9895 59.1798 12.4364 59.5876 12.3426C60.0704 12.2301 60.5626 12.1411 61.0642 12.0848C63.4267 11.8176 65.8173 12.127 67.8892 13.4067C69.6001 14.452 70.861 16.2332 71.0954 18.1832C71.4517 20.8036 70.022 23.3723 67.997 25.0598C66.1782 26.6067 63.9376 27.6004 61.6361 28.2332C59.297 28.8239 56.8735 29.302 54.3845 28.8004C52.7017 28.4536 51.2017 27.3707 50.7329 25.7442C49.5001 21.5864 54.4735 15.6332 57.5298 12.9661Z"
        fill="white"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1_221"
        x1="5.2717"
        y1="5.27165"
        x2="114.728"
        y2="114.728"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#E03663" />
        <stop offset="0.5" stop-color="#B60029" />
        <stop offset="1" stop-color="#7D0119" />
      </linearGradient>
      <clipPath id="clip0_1_221">
        <rect width="120" height="120" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default StygigLogo;
