import React, { useEffect } from "react";
import SecondaryHeader from "../Header/SecondaryHeader";
import "./TermsAndConditions.css";
const TermsAndConditions = ({ openModal }) => {
  const currentYear = new Date().getFullYear();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="tcMain">
      <SecondaryHeader
        openModal={openModal}
        subtitle={"Terms and Conditions"}
      />

      <div className="tcBody">
        <div className="tcBold">Welcome to StyGig</div>
        <div>
          These Terms of Use (or "Terms") govern your use of StyGig App, except
          where we expressly state that separate terms (and not these) apply,
          and provide information about the StyGig Service (the "Service"),
          outlined below. <br />
          When you create an StyGig account or use StyGig, you agree to these
          Terms.
        </div>
        <div>
          Please read the following terms and conditions carefully before using
          our services. By using our app, you agree to be bound by these terms
          and conditions.
        </div>
        <div className="tcBold">User Conduct</div>
        <ul>
          <li>You must be at least 13 years old to use our app.</li>
          <li>
            You must provide accurate and complete information when creating an
            account.
          </li>
          <li>
            You must not use our app for any illegal or unauthorised purpose.
          </li>
          <li>
            You must not harass, threaten, or harm other users of our app.
          </li>
          <li>
            You must not upload or share any content that violates any laws,
            infringes on any intellectual property rights, or is otherwise
            offensive or inappropriate.
          </li>
          <li>
            You are solely responsible for your interactions with other users of
            our app. Intellectual Property
          </li>
          <li>
            Our app and all of its content, including but not limited to text,
            graphics, logos, images, and software, are the property of our
            company or our licensors and are protected by copyright, trademark,
            and other intellectual property laws.
          </li>
          <li>
            You may not copy, modify, distribute, or otherwise use any of our
            content without our prior written permission.
          </li>
          <li>
            By uploading or sharing any content on our app, you grant us a
            non-exclusive, royalty free, perpetual, irrevocable, and fully sub
            licensable right to use, reproduce, modify, adapt, publish,
            translate, create derivative works from, distribute, and display
            such content in any form, media, or technology. User Content
          </li>
          <li>
            You are solely responsible for any content that you upload or share
            on our app.
          </li>
          <li>
            You represent and warrant that you have all necessary rights and
            permissions to upload or share such content, and that such content
            does not violate any laws, infringe on any intellectual property
            rights, or otherwise harm any person or entity.
          </li>
          <li>
            We reserve the right to remove any content that we deem
            inappropriate or that violates these terms and conditions.{" "}
          </li>
        </ul>
        <div className="tcBold">Privacy</div>
        <ul>
          <li>
            We collect and use your personal information in accordance with our
            privacy policy, which is available on our app.
          </li>
          <li>
            By using our app, you consent to our collection and use of your
            personal information as described in our privacy policy.
          </li>
        </ul>
        <div className="tcBold">Termination</div>
        <ul>
          <li>
            We may terminate your access to our app at any time for any reason,
            without notice or liability.
          </li>
          <li>
            You may terminate your account at any time by contacting us at
            contact@stygig.com Disclaimer of Warranties
          </li>
          <li>
            Our app is provided on an "as is" and "as available" basis, without
            any warranties of any kind, express or implied.
          </li>
          <li>
            We do not warrant that our app will be error-free or uninterrupted,
            or that any defects will be corrected.
          </li>
          <li>
            We do not endorse any content uploaded or shared by users of our
            app, and we are not responsible for any such content.
          </li>
        </ul>
        <div className="tcBold">Limitation of Liability </div>

        <ul>
          <li>
            We shall not be liable for any damages arising from your use of our
            app, including but not limited to direct, indirect, incidental,
            punitive, and consequential damages.
          </li>
        </ul>
      </div>
      <div className="tcFooter">@{currentYear} StyGig</div>
    </div>
  );
};

export default TermsAndConditions;
