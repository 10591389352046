import React from "react";

const TwitterIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    // width="19"
    // height="19"
    viewBox="0 0 19 19"
    fill="none"
  >
    <g clip-path="url(#clip0_1_211)">
      <path
        d="M14.9633 0.912598H17.8766L11.5116 8.18801L19 18.0862H13.1369L8.54525 12.0822L3.29017 18.0862H0.37525L7.18358 10.3041L0 0.913389H6.01192L10.1626 6.40122L14.9633 0.912598ZM13.9413 16.343H15.5555L5.13475 2.56481H3.40258L13.9413 16.343Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_211">
        <rect width="19" height="19" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default TwitterIcon;
