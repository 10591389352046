import React, { useEffect, useRef } from 'react'
import BlackOutfitImage from '../../assets/Vectors/BlackOutfitImage'
import RedOutfitImage from '../../assets/Vectors/RedOutfitImage'
import TraditionalImage from '../../assets/Vectors/TraditionalImage'
import YellowImage from '../../assets/Vectors/YellowImage'
import "./CardsDeck.css"
const CardsDeck = () => {
  const cardsDeckRef = useRef();

  useEffect(() => {
    const handleScroll = () => {
      const scrollPercentage = window.pageYOffset / (document.body.offsetHeight - window.innerHeight);
      document.documentElement.style.setProperty('--scroll', scrollPercentage);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <div className="imagesContainer" ref={cardsDeckRef}>
        <div className="imageCard1">
          <TraditionalImage />
        </div>
        <div className="imageCard2">
          <RedOutfitImage />
        </div>
        <div className="imageCard3">
          <YellowImage />
        </div>
        <div className="imageCard4">
          <BlackOutfitImage />
        </div>
      </div>
  )
}

export default CardsDeck