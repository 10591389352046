import React from "react";
import HoodieImage from "../assets/Images/Hoodie.png";
import RedSunglasses from "../assets/Images/RedSunglass.png";
import Streetwear from "../assets/Images/Streetwear.png";
import ExploreExpressEmpower from "../assets/Vectors/ExploreExpressEmpower";
import CardsDeck from "./CardsDeck/CardsDeck";
import Chips from "./ChipsSection/Chips";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import HoverCardGallery from "./HoverCardGallery/HoverCardGallery";
import "./Main.css";
const Main = ({ openModal }) => {
  return (
    <>
      <button className="getAppButton" onClick={openModal}>
        Get App
      </button>
      <Header />
      <div className="mainContainer">
        <CardsDeck />
        <div className="gradientText">
          Millions Celebrate their unique style with us
        </div>
        <div className="typography1">
          <div>
            We're here to celebrate individuality, creativity, and all things
            fashion. Explore the latest trends, share your unique style, and
            connect with a community of fashion enthusiasts like never before.
          </div>
          <div>
            Whether you're a trendsetter, a fashionista, or just looking for
            daily inspiration, StyGig is your platform to express, inspire, and
            be inspired.
          </div>
          <div>Join us in the world of fashion redefined.</div>
        </div>
        <div className="stylewstygig">#StylewithStyGig</div>
        <div className="cardsContainer">
          <img src={RedSunglasses} alt="Red Sunglasses" className="card1" />

          <img src={Streetwear} alt="Streetwear" className="card2" />
          <div className="card3">
            <ExploreExpressEmpower />
          </div>
          <img src={HoodieImage} alt="HoodieImage" className="card4" />
        </div>
        <div className="flauntText">Flaunt new styles, swap new looks</div>
        <HoverCardGallery />
        <Chips />
        <Footer />
      </div>
    </>
  );
};

export default Main;
